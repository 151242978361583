export default {
	"ar": {
		"Services": {
			"services": {
				"Additional Information": "معلومات إضافية",
				"Address": "العنوان",
				"Address in Local Language": "العنوان باللغة المحليّة",
				"All Categories": "جميع الفئات",
				"All Services": "جميع الخدمات المتاحة",
				"am": "صباحاً",
				"Back": "رجوع",
				"Call": "اتصل بـ",
				"Closed": "مغلق",
				"Copied": "تم النسخ",
				"Cost of service":"تكلفة الخدمة",
				"Email": "البريد الإلكتروني",
				"Filter": "يتقدم",
				"Get directions": "للاستدلال على الاتجاهات",
				"Languages Spoken": "اللغات المستخدمة",
				"LAST_UPDATED": "آخر تحديث لهذه الصفحة كان بتاريخ:",
				"Location": "موقع",
				"Locations": "مواقع",
				"Locations in": "n/a",
				"Map": "الخريطة",
				"Map view": "خريطة",
				"Municipalidades": "مدن",
				"Nearby Services": "n/a",
				"No services found": "لا توجد خدمات",
				"OTHER_SERVICES": "خدمات أخرى في هذا المكان",
				"Open 24/7": "هذه الخدمة مُتاحة على مدار الساعة 24/7",
				"pm": "مسـاءً",
				"Services": "الخـدمات",
				"Service Detail": "تفاصيل عن الخدمة",
				"Services in": "الخدمات في",
				"Service Map": "خريطة الخدمة",
				"Service Provider": "مقدّم الخدمة",
				"Service Categories": "فئات الخدمات المختلفة",
				"Show All": "عرض الكل",
				"Show More": "أظهر المزيد",
				"Visiting hours": "ساعات الزّيارة",
				"Website": "الموقـع الإلكترونـي",
				"ServiceInvalid": "الخدمة لم تعد متوفرة",
			}
		}
	},
	"en": {
		"Services": {
			"services": {
				"Additional Information": "Additional Information",
				"Address": "Address",
				"Address in Local Language": "Address in Local Language",
				"All Categories": "All Categories",
				"All Services": "All Services",
				"am": "am",
				"Back": "Back",
				"Call": "Call",
				"Closed": "Closed",
				"Copied": "Copied",
				"Cost of service": "Cost of service",
				"Email": "Email",
				"Filter": "Apply",
				"Get directions": "Get directions",
				"Languages Spoken": "Languages Spoken",
				"LAST_UPDATED": "Last update: ",
				"Location": "Location",
				"Locations": "Locations",
				"Locations in": "Locations in",
				"Map": "Map",
				"Map view": "Map view",
				"Municipalidades": "Cities",
				"Nearby Services": "n/a",
				"No services found": "No services found",
				"OTHER_SERVICES": "Other services at this location",
				"Open 24/7": "Open 24/7",
				"pm": "pm",
				"Services": "Services",
				"Service Detail": "Service Detail",
				"Services in": "Services in",
				"Service Map": "Service Map",
				"Service Provider": "Service Provider",
				"Service Categories": "Service Categories",
				"Show All": "Show All",
				"Show More": "Show More",
				"Visiting hours": "Visiting hours",
				"Website": "Website",
				"ServiceInvalid": "Service is no longer Valid"
			}
		}
	},
	"es": {
		"Services": {
			"services": {
				"Additional Information": "Información Adicional",
				"Address": "Dirección",
				"Address in Local Language": "Dirección en idioma local",
				"All Categories": "Todas las Categorías",
				"All Services": "Todos los Servicios",
				"am": "am",
				"Back": "Volver",
				"Call": "Llamar",
				"Closed": "Cerrado",
				"Copied": "Copiado",
				"Cost of service": "Costo de servicio",
				"Email": "Email",
				"Filter": "Aplicar",
				"Get directions": "Obtener las direcciones",
				"Languages Spoken": "Idiomas Hablados",
				"LAST_UPDATED": "Última actualización: ",
				"Location": "Ubicación",
				"Locations": "Departamentos",
				"Locations in": "Ubicaciones en",
				"Map": "Mapa",
				"Map view": "Mapa",
				"Municipalidades": "Municipalidades",
				"Nearby Services": "n/a",
				"No services found": "No se encontraron servicios",
				"OTHER_SERVICES": "Otros servicios en esta ubicación",
				"Open 24/7": "Abierto 24/7",
				"pm": "pm",
				"Services": "Servicios",
				"Services in": "Servicios en",
				"Service Detail": "Detalle de servicio",
				"Service Map": "Mapa de Servicios",
				"Service Provider": "Organización",
				"Service Categories": "Categorías de Servicios",
				"Show All": "Mostrar Todos",
				"Show More": "Mostrar Más",
				"Visiting hours": "Horario de visita",
				"Website": "Sitio web",
				"ServiceInvalid": "El servicio ya no es válido"
			}
		}
	},
	"fa": {
		"Services": {
			"services": {
				"Additional Information": "اطلاعات اضافی:",
				"Address": "آدرس",
				"Address in Local Language": "آدرس به زبان محلی",
				"All Categories": "همه دسته بندی ها",
				"All Services": "همه خدمات",
				"am": "صبح",
				"Back": "بازگشت",
				"Call": "تماس",
				"Closed": "بسته",
				"Copied": "کپی‌شده",
				"Cost of service":"هزینه ی خدمات",
				"Filter": "درخواست دادن",
				"Email": "پست الکترونیکی",
				"Get directions": "دریافت مسیر",
				"Languages Spoken": "زبان‌هایی که به آن‌ها صحبت می‌شود",
				"LAST_UPDATED": "این صفحه آخرین بار در این تاریخ به‌روز شده:",
				"Location": "محل",
				"Locations": "مکان‌ها",
				"Locations in": "n/a",
				"Map": "نقشه",
				"Map view": "نقشه",
				"Municipalidades": "شهرها",
				"Nearby Services": "n/a",
				"No services found": "هیچ خدماتی پیدا نشد ",
				"OTHER_SERVICES": "سایر خدمات در این منطقه",
				"Open 24/7": "در طول 24 ساعت روز و 7 روز هفته باز است ",
				"pm": "بعدازظهر",
				"Services": "خدمات",
				"Services in": "خدمات در",
				"Service Detail": "جزییات خدمات",
				"Service Map": "نقشه خدمات",
				"Service Provider": "ارائه‌کننده خدمات",
				"Service Categories": "طبقه‌بندی‌ خدمات",
				"Show All": "نمایش همه",
				"Show More": "بیشتر نشان بده، اطلاعات بیشتر",
				"Visiting hours": "ساعات بازدید",
				"Website": "وبسایت",
				"ServiceInvalid": "این سرویس دیگر در دسترس نمی باشد."
			}
		}
	},
	"fr": {
		"Services": {
			"services": {
				"Additional Information": "Information supplémentaire",
				"Address": "Adresse",
				"Address in Local Language": "Adresse en langue locale",
				"All Categories": "Toutes catégories",
				"All Services": "Tous les services",
				"am": "du matin",
				"Back": "Retour",
				"Call": "Appeler",
				"Closed": "Fermé",
				"Copied": "Copié",
				"Cost of service":"Le coût du service",
				"Email": "Courrier électronique",
				"Filter": "Appliquer",
				"Get directions": "Obtenir des directions",
				"Languages Spoken": "Langues parlées",
				"LAST_UPDATED": "Dernière mise à jour:",
				"Location": "Position",
				"Locations": "Position",
				"Locations in": "n/a",
				"Map": "Carte",
				"Map view": "Carte",
				"Municipalidades": "Villes",
				"Nearby Services": "n/a",
				"No services found": "Aucun service trouvé",
				"Open 24/7": "Ouvert 24/7",
				"OTHER_SERVICES": "Autres services à cet endroit",
				"pm": " de l'après-midi",
				"Services": "Services",
				"Services in": "Services à",
				"Service Detail": "Detail du service",
				"Service Map": "Carte de service",
				"Service Provider": "Fournisseur de services",
				"Service Categories": "Catégories de services",
				"Show All": "Montre tout",
				"Show More": "Montre plus",
				"Visiting hours": "Heures d'ouverture",
				"Website": "Site Internet:",
				"ServiceInvalid": "Le service n'est plus valide"
			}
		}
	},
	"ur": {
		"Services": {
			"services": {
				"Additional Information": "اضافی معلومات",
				"Address": "پتہ",
				"Address in Local Language": "مقامی زبان میں پتہ/ ایڈریس",
				"All Categories": "تمام زمرے",
				"All Services": "تمام خدمات",
				"am": "قبل ازدوپہر",
				"Back": "پیچھے",
				"Call": "کال کریں",
				"Closed": "بند",
				"Copied": "کی ہوئی کاپی",
				"Cost of service":"سروس کی قیمت",
				"Email": "ای میل",
				"Filter": "درخواست دیں",
				"Get directions": "ہدایات حاصل کریں",
				"Languages Spoken": "بولی جانے والی زبانیں",
				"LAST_UPDATED": "یہ صفحہ آخری دفعہ اپ ڈیٹ کیا گیا:",
				"Location": "مقامات",
				"Locations": "مقامات",
				"Locations in": "n/a",
				"Map": "نقشہ",
				"Map view": "نقشہ",
				"Municipalidades": "شہروں",
				"Nearby Services": "n/a",
				"No services found": "کوئی خدمات نہیں ملیں",
				"OTHER_SERVICES": "اس مقام پر دیگر خدمات",
				"Open 24/7": " 24/7 کھلی ہے",
				"pm": "بعد دوپہر",
				"Services": "خدمات",
				"Service Detail": "خدمات کی تفصیل",
				"Services in": "میں خدمات",
				"Service Map": "خدمت کا نقشہ",
				"Service Provider": "خدمات مہیا کرنے والا",
				"Service Categories": "خدمات کا زمرہ جات",
				"Show All": "سارے دکھاو",
				"Show More": "مزید دکھائیں",
				"Visiting hours": "ملنے کے اوقات",
				"Website": "ویب سائٹ",
				"ServiceInvalid": "سروس"
			}
		}
	}
}
