module.exports = {
	languageDictionary: {
		en: "en-US",
		ar: "ar-001",
		fa: "fa-AF",
	},
	
	"refugee.info": {
	},
	"staging.refugee.info": {
	},
	"qa.refugee.info": {
	},
	"khabrona.info": {
	},	
	"qa.khabrona.info": {
	},	
	"staging.khabrona.info": {
	},	
	"cuentanos.org": {
	},
	"staging.cuentanos.org": {
	},
	"qa.cuentanos.org": {
	},
	"simaetbhatha.com": {
	},
	"staging.simaetbhatha.com": {
	},
	"qa.simaetbhatha.com": {
	},
	"localhost": {
	},
};
