
export default {
	// instance: 'cuentanos.org',
	// instance: 'khabrona.info',
	// instance: 'refugee.info',
	instance: 'simaetbhatha.com',
	env: 'qa',
	// env: 'staging',
	// env: 'www',
}
